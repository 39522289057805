import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.48_@babel+core@7.24.7_babel-plugin-react-compiler@0.0.0-experimental-59295_r77jag3ju2whfwkbdhqinoow5a/node_modules/next/dist/client/components/render-from-template-context.js");
